import common from "./common";
import error from "./error";
import home from "./home";
import information from "./information";
import itemDetail from "./item-detail";
import reminder from "./reminder";
import service from "./service";
import serviceDetail from "./service-detail";
import setting from "./setting";
import shops from "./shops";
import track from "./track";
import vendor from "./vendor";
export default {
  shops,
  error,
  common,
  itemDetail,
  information,
  home,
  track,
  setting,
  reminder,
  vendor,
  service,
  serviceDetail,
};
