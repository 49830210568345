export default {
  detail_asset: "Detail Aset",
  qr_code: "QR Code",
  specification: "Spesifikasi",
  access_rights: "Hak Akses",
  edit: "Edit",
  add: "Add",
  pending: "Pending",
  grand_total: "Total Biaya",

  next_service: "Servis Selanjutnya",
  previous_service: "Servis Terakhir",
  service_history: "Riwayat Servis",
  ongoing_service: "Pending",
  detail: "Detail",

  set_private: "Set Private",
  set_public: "Set Public",
  edit_detail: "Edit Detail",
  qrid_code: "Kode QRID",
  service: "Tambah Servis",
  record_service: "Catat Servis",

  title_add_service: "Tambah Servis",
  add_service: "+ Servis",
  choose_service_item: "Pilih Item Servis",
  edit_service: "Edit Servis",
  service_item: "Item Servis",
  items: "{{number}} items",
  item: "{{number}} item",

  description: "Keterangan",
  service_date: "Date",

  shop: "Toko",
  account: "Account",

  default: "Regular",
  repeat: "Berkala",

  back: "Kembali",
  back_to_previous_category: "Kembali ke kategori sebelumnya",

  delete_service_confirmation: "Apakah anda yakin ingin menghapus servis ini?",
  temporary_save: "Pending",
  completed: "Selesai",

  on_process: "On Process",
  pending_items: "{{number}} Item Servis",
  personal: "Personal",

  claim_ownership_title: "Item ini belum memiliki pemilik",
  claim_ownership_text: "Segera klaim sekarang untuk menjadi pemilik item ini.",
  claim_ownership_confirmation: "Apakah anda yakin mengklaim item ini?",
  claim_now: "Klaim untuk Sendiri",
  claim_now_description:
    "Dipakai untuk klaim aset milik sendiri. Hanya akun anda yang dapat hak akses",
  claim_other: "Klaim untuk Orang Lain",
  claim_other_description:
    "Dipakai untuk klaim aset milik orang lain/customer. Hanya akun tersebut yang dapat hak akses",
  claim_business: "Klaim untuk Bisnis",
  claim_business_description:
    "Dipakai untuk klaim aset milik perusahaan. Semua user dalam akun bisnis ini juga dapat hak akses",
  date: "Tanggal",
  enter_service: "Masuk Servis",
  collapse_all: "Collapse All",
  expand_all: "Expand All",

  reset_label: "Reset Label",
  service_history_placeholder: "Search",
  no_service_history: "Tidak Ada Riwayat Servis",
  see_all: "Lihat selengkapnya",
  business: "Bisnis",
  see_item_detail: "Lihat Detail Aset",

  add_customer_data: "Tambah Data Pelanggan",
  edit_customer_data: "Edit Data Pelanggan",
  set_customer: "Set Pelanggan",
  info_customer: "Info Pelanggan",

  detail_seen: "Detail pelanggan ini hanya terlihat oleh tim kamu",
  history: "Riwayat",
  reminder: "Reminder",
  share_via_whatsapp: "Share Via Whatsapp",
  owner: "Owner",
  co_owner: "Co-owner",
  admin: "Admin",
  guest: "Guest",

  reminder_follow_up: "Follow Up",
  reminder_lost: "Lost",
  reminder_disable: "Matikan",
  reminder_active: "Aktifkan",
  reminder_postpone: "Undur",
  reminder_history: "Riwayat",
  reminder_service: "Servis",
  next_reminder: "Servis Selanjutnya",
  dont_remind: "Jangan Ingatkan Lagi",
  remind_back: "Aktifkan Kembali",
  remind_back_confirmation: "Yakin mau aktifkan reminder servis ini?",
  remind_confirmation: "Yakin mau matikan reminder servis ini?",
  remind_active: "Aktifkan",
  remind_close: "Matikan",
  remind_again: "Ingatkan Kembali",
  remind_follow_up: "Follow Up via WhatsApp",
  reminder_send_message: "Kirim Pesan",

  kilometer: "Kilometer",
  kilometer_in: "Kilometer masuk",
  type_service_here: "Ketik servis di sini",
  note: "Tambah Catatan",
  edit_note: "Edit Catatan",
  update_stock: "Update Stok",
  edit_stock: "Edit Stok",

  handover: "Pindah Tangan",
  necessity: "Keperluan",
  consumer: "Consumer",
  usage: "Pakai",
  rent: "Sewa",
  edit_rent: "Edit Penyewaan",
  edit_consumer: "Edit Pemakai",
  edit_location: "Edit Lokasi",
  new_consumer: "Pemakai Baru",
  new_rent: "Sewa Baru",
  new_location: "Lokasi Baru",
  move_location: "Pindah Lokasi",
  return: "Pengembalian",
  completed_rent: "Sewa Selesai",
  extend_rent: "Extend Sewa",

  add_stock: "Tambah Stok",
  reduce_stock: "Kurang Stok",
  delete_user_item: "Hapus Aset",
  no_reminder: "Tidak Ada Reminder",

  report_inspection: "Laporan Inspeksi",
  report_meter: "Laporan Meter",
  report_stock: "Laporan Stok",

  time: "Waktu",
  team: "Team",
  good: "Baik",
  broken: "Kurang",
  attachment: "Lampiran",
  stand: "Stand",
  diff: "Selisih",
  change: "Change",
  update: "Update",
  balance: "Saldo",
  asset_deleted_info: "Aset sudah dihapus oleh owner {{deletedAt}}",
  no_data_chart: " Tidak ada data di tahun ini",

  total_usage_meter: "Total pemakaian: {{total}} kwh",
  average_usage_meter: "Avg pemakaian: {{average}} kwh/bln",

  total_stock_in: "Total stok masuk: {{total}} {{prefix}}",
  average_stock_in: "Avg stok masuk: {{average}} {{prefix}}",
  total_stock_out: "Total stok keluar: {{total}} {{prefix}}",
  average_stock_out: "Avg stok keluar: {{average}} {{prefix}}",

  total_record: "Total pencatatan: {{total}} catatan",
  average_record: "Avg pencatatan: {{average}} catatan/bln",

  total_cost: "Total Biaya: Rp {{total}}",
  average_cost: "Avg Biaya: Rp {{average}}/bln",

  total_rent: "Total Sewa: Rp {{total}}",
  average_rent: "Avg Sewa: Rp {{average}}/bln",

  total_bbm: "Total BBM: Rp {{total}}",
  average_bbm: "Avg BBM: Rp {{average}}/bln",

  stock_amount: "Stok: {{amount}}",
  min_stock_amount: "Min Stok: {{amount}}",
  this_month_total_service: "Bulan ini: {{total}} data",

  delete_multiple_user_item_confirmation:
    "Yakin akan menghapus {{total}} unit aset kamu? Aset yang sudah dihapus tidak bisa dipulihkan kembali.",

  note_the_meter: "Catat Meter",

  running_hour: "Running Hour",
};
