export default {
  shop: "Toko",
  service_date: "Tanggal Servis",
  description: "Title",
  kilometer: "Kilometer",
  item_service: "Item Servis",

  staff: "Staff",

  service_entry: "Masuk Servis",
  service_item: "Detail Service",

  delete_history: "Hapus Riwayat",
  delete_history_message: "Apakah anda yakin akan melakukan penghapusan ini?",
  search_history_service: "Cari riwayat servis",
  search_reminder: "Cari reminder",
  month: "Bulan",
  stand: "Stand: {{stand}}",
  service_requested_by: "Servis di-request oleh {{name}}",
  responsed_by: "Direspon oleh {{name}}",
  requested_by: "Di-request oleh {{name}}",
  see_request: "Lihat Request",
  see_service: "Lihat Servis",
  waiting_for_response: "Menunggu respon",
  responsed_name: "Direspon {{name}}",
  reset_response: "Reset Respon",
  reset_response_confirmation:
    "Apakah kamu ingin mereset respon request servis?",
  serviced_name: "Diservis {{name}}",
  serviced_by: "Diservis oleh {{name}}",
  delete_service: "Hapus Servis",
  delete_service_confirmation:
    "Apakah kamu ingin menghapus catatan servis ini?",

  month_period: "Periode (Bulan)",

  end_at: "Berakhir",
};
